import { decorate, observable, action, computed } from 'mobx'
import * as moment from 'moment'

export default class User {
  user = {}
  name = 'John'
  mail = ''
  age = 42
  showAge = false
  isUserSet = false
  platform = ''
  displayName = ''
  graphAccessToken = ''
  oid = ''
  tenantId = ''
  isAdmin = false
  role = 0
  UPN = ''
  tenantSettings = null
  tenant = null
  hasGraphSubscription = false
  impersonateMode = false
  impersonateOldUser = null
  demoMode = false
  hasAdminConsent = false
  userSettings = {}
  canChangeGeneralSettings = true
  isPartner = false

  setUser(user) {
    this.displayName = user.displayName
    this.oid = user.oid
    this.graphAccessToken = user.graphAccessToken
    this.isUserSet = true
    this.UPN = user.UPN
    this.mail = user.mail
    this.tenantId = user.tenantId
    this.tenant = user.tenant
    this.tenantSettings = user.tenant.settings
    if (user.role === 2) {
      this.isAdmin = true
    }
    if (user.tenant.graphSubscription) {
      this.hasGraphSubscription = true
    }
    if (user.tenant.partnerUTMCode) {
      this.isPartner = true
    }
    this.role = user.role
    this.impersonateMode = user.impersonateMode
    this.impersonateOldUser = user.impersonateOldUser
    this.demoMode = user.demoMode
    this.hasAdminConsent = user.hasAdminConsent
    this.userSettings = user.userSettings
    this.canChangeGeneralSettings = user.canChangeGeneralSettings

    if (user.demoMode) {
      localStorage.setItem('demo', 'true')
      localStorage.setItem('user', user.displayName)
    }

    if (moment().diff(moment(user.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newuser')
      }
    }

    if (moment().diff(moment(user.tenant.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newtenant')
      }
    }
  }
  setTenantSettings(settings) {
    this.tenantSettings = settings
    this.tenant.settings = settings
  }

  setUserLicenseModel(userLicenseModel) {
    this.tenant.userLicenseModel = userLicenseModel
  }

  setTenantSecurityGroup(secGroup) {
    this.tenant.userLicenseModelSecurityGroup = secGroup
  }

  setTenant(tenant) {
    this.tenant = tenant
  }
  setUserSettings(settings) {
    this.userSettings = settings
  }

  toggleGraphSubscription(value) {
    this.hasGraphSubscription = value
  }
}
// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(User, {
  user: observable,
  name: observable,
  age: observable,
  showAge: observable,
  isUserSet: observable,
  platform: observable,
  displayName: observable,
  graphAccessToken: observable,
  oid: observable,
  tenantId: observable,
  isAdmin: observable,
  UPN: observable,
  tenantSettings: observable,
  tenant: observable,
  setUser: action,
  setTenant: action
})
