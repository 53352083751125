const MODULES = {
  MEETINGS: 'MEETINGS',
  GOALS: 'GOALS',
  REVIEWS: 'REVIEWS',
  SURVEYS: 'SURVEYS',
  RECOGNITIONS: 'RECOGNITIONS',
  FEEDBACK: 'FEEDBACK',
  TASKS: 'TASKS',
  NOTES: 'NOTES',
  GENERAL: 'GENERAL',
  LICENSE: 'LICENSE'
}

export { MODULES }
